import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBeNrC6RxBJKgnwAoPoazhAEnLjc8pQQvU",
  authDomain: "app-macros-node.firebaseapp.com",
  projectId: "app-macros-node",
  storageBucket: "app-macros-node.appspot.com",
  messagingSenderId: "1024292540296",
  appId: "1:1024292540296:web:238f5b0c138f1b5467f43d",
  measurementId: "G-5K85B7CWG2"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export {app, analytics, db}