import React from "react";
import "./index.css";
import {
  faArrowDown,
  faArrowRight,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RowTable = (props) => {
  const { tarefa, index } = props;
  let el = (
    <FontAwesomeIcon
      title="Em Andamento"
      className="icon-andamento"
      icon={faArrowRight}
    />
  );

  if (tarefa.previsto > tarefa.realizado) {
    el = (
      <FontAwesomeIcon
        title="Atraso"
        className="icon-atraso"
        icon={faArrowDown}
      />
    );
  } else if (tarefa.previsto < tarefa.realizado) {
    el = (
      <FontAwesomeIcon
        title="Antecipado"
        className="icon-antecipado"
        icon={faArrowUp}
      />
    );
  }
  return (
    <>
      <tr key={index}>
        <td className="text-center">{index + 1}</td>
        <td className="text-center">{tarefa.obra}</td>
        <td className="text-center">{tarefa.local}</td>
        <td className="text-center">{tarefa.servico}</td>
        <td className="text-center">{tarefa.empreiteiro}</td>
        <td className="text-center">{tarefa.justificativa}</td>
        <td className="text-center">{tarefa.previsto}%</td>
        <td className="text-center">{tarefa.realizado}%</td>
        <td className="text-center">{el}</td>
      </tr>
    </>
  );
};

export default RowTable;
