import React, { useEffect, useState } from "react";
import "./index.css";
import { Container, Table, Form } from "react-bootstrap";
import RowsTarefa from "./Rows";

const TabelaObra = ({ tarefas, info, setInfo, filterNowEmpreiteiro }) => {
  const [tarefaFilters, setTarefaFilters] = useState(null);

  useEffect(() => {
    if(tarefas){
      if (info.showSumary) {
        setTarefaFilters(tarefas);
      } else {
        setTarefaFilters(
          tarefas.filter((tarefa) => tarefa.is_sumary === info.showSumary)
        );
      }
      if(filterNowEmpreiteiro !== "SEM"){
        setTarefaFilters(
          tarefas.filter((tarefa) => tarefa.empreiteiro === filterNowEmpreiteiro)
        );
      }
    }
  }, [tarefas, info, filterNowEmpreiteiro]);

  return (
    <Container fluid>
      <div className="action-obra">
        <Form id="is-sumary">
          <Form.Check
            type="switch"
            label="Exibir Resumo"
            checked={info.showSumary}
            onClick={() => {
              setInfo((prev) => ({
                ...prev,
                showSumary: !prev.showSumary,
              }));
            }}
          />
        </Form>
      </div>
      <Table className="text-[0.7rem]" striped bordered hover>
        <thead>
          <tr>
            <th>QT</th>
            <th>LOCAL</th>
            <th>SERVIÇO</th>
            <th>EMPREITEIRO</th>
            <th>JUSTIFICATIVA</th>
            <th>INÍCIO</th>
            <th>DURAÇÃO</th>
            <th>TÉRMINO</th>
            <th>PREVISTO</th>
            <th>REALIZADO</th>
            <th>SITUAÇÃO</th>
          </tr>
        </thead>
        <tbody>
          {tarefaFilters ? (
            tarefaFilters.map((tarefa, index) => (
              <RowsTarefa key={index} tarefa={tarefa} index={index} />
            ))
          ) : (
            <tr>
              <td className="text-center" colSpan="10">
                Nenhuma atividade foi encontrada
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
};

export default TabelaObra;
