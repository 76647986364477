import { Container, Form } from "react-bootstrap";
import ChartJustificativa from "../../chart-justificativa";
import ChartEmpreiteiro from "../../chart-empreiteiro";
import TableTarefas from "../../Table";
import { useEffect, useState } from "react";
import { faTrashCan, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./index.css";

function DashboardAba(props) {
  const { data, page } = props;
  const [isShow, setIsShow] = useState(false);

  const [dadosFilter, setDadosFilter] = useState({
    empreiteiroEmAtraso: [],
    justificativaAtraso: [],
  });

  const [filterTable, setFilterTable] = useState({
    empreiteiro: null,
    justificativa: null,
    atraso: false,
  });

  useEffect(() => {
    setDadosFilter({
      empreiteiroEmAtraso: data.empreiteiroEmAtraso,
      justificativaAtraso: data.justificativaAtraso,
    });
  }, [data]);

  useEffect(() => {
    //  console.log(dadosFilter)
  }, [dadosFilter]);

  useEffect(() => {
    if (
      filterTable.justificativa ||
      filterTable.empreiteiro ||
      filterTable.atraso
    ) {
      setIsShow(true);
    } else {
      setIsShow(false);
    }
  }, [filterTable]);

  return (
    <Container fluid className="content">
      <div className="painel">
        <div className="contador">
          <div>
            <span>
              {page != "TODOS"
                ? data.tarefas[0]?.status
                : new Date().toLocaleDateString()}
            </span>
            <span>Data Status</span>
          </div>
          <div>
            <span>{data.total}</span>
            <span>Total de Serviços</span>
          </div>
          <div>
            <span name="red">{data.atraso}</span>
            <span>Total em Atraso</span>
          </div>
          <div>
            <span>{((1 - data.atraso / data.total) * 100).toFixed(2)}%</span>
            <span>PPC</span>
          </div>
        </div>
      </div>
      <div className="charts">
        <div className="chart-body">
          <ChartEmpreiteiro
            data={data}
            page={page}
            setFilterTable={setFilterTable}
            filterTable={filterTable}
            dadosFilter={dadosFilter}
            setDadosFilter={setDadosFilter}
          />
          <ChartJustificativa
            data={data}
            page={page}
            setFilterTable={setFilterTable}
            filterTable={filterTable}
            dadosFilter={dadosFilter}
            setDadosFilter={setDadosFilter}
          />
        </div>

        <div className="tabela">
          <div className="action-filter">
            <span>ATIVIDADES</span>
            <div className="action-filter-body">
              {filterTable.empreiteiro && (
                <span
                  onClick={() => {
                    setFilterTable({ ...filterTable, empreiteiro: null });
                  }}
                  title="Remover filtro"
                >
                  {filterTable.empreiteiro}

                  <FontAwesomeIcon
                    title="Limpar Filtro"
                    className="icon-close"
                    icon={faClose}
                  />
                </span>
              )}
              {filterTable.justificativa && (
                <span
                  onClick={() => {
                    setFilterTable({ ...filterTable, justificativa: null });
                  }}
                  title="Remover filtro"
                >
                  {filterTable.justificativa}

                  <FontAwesomeIcon
                    title="Limpar Filtro"
                    className="icon-close"
                    icon={faClose}
                  />
                </span>
              )}
              <Form>
                <Form.Check // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  label="Em atraso"
                  checked={filterTable.atraso}
                  onClick={() => {
                    setFilterTable((prev) => ({
                      ...prev,
                      atraso: !prev.atraso,
                    }));
                  }}
                />
              </Form>
            </div>
          </div>
          <TableTarefas data={data} filterTable={filterTable} />
        </div>
      </div>
    </Container>
  );
}

export default DashboardAba;
