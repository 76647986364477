import { Container, Form } from "react-bootstrap";
import logo from "../../img/fv.png";
import logoParceiro from "../../img/LOGO_LUCAS.png";
import logoCliente from "../../img/logo-cliente.jpeg";
import "./index.css";
import TabObra from "../../components/Tab";
import { getObras } from "../../api/model";
import { useState } from "react";

function App() {
  const [dtSemana, setDtSemana] = useState(null);

  return (
    <Container fluid className="container-home">
      <div className="nav-header">
        <div className="body-empresa">
          <img
            alt=""
            src={logo}
            width="80"
            height="60"
            className="d-inline-block"
          />
          <span>Fechamento Semanal</span>
        </div>

        <div className="logos">
          <img
            alt=""
            src={logoParceiro}
            width="160"
            height="60"
            className="d-inline-block"
          />
          <img
            alt=""
            src={logoCliente}
            width="160"
            height="60"
            className="d-inline-block"
          />
        </div>
      </div>
      <div className="controller">
        {/* <Form.Select
          onChange={(e) => {
            setDtSemana(e.target.value);
          }}
          aria-label="Default select example"
        >
          <option value="0">TODOS</option>
          <option value="02/02/2024">02/02/2024</option>
          <option value="09/02/2024">09/02/2024</option>
          <option value="16/02/2024">16/02/2024</option>
          <option value="23/02/2024">23/02/2024</option>
        </Form.Select> */}
      </div>

      <TabObra obras={getObras} dtSemana={dtSemana} />
    </Container>
  );
}

export default App;
