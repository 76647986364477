import React from "react";
import { Card } from "react-bootstrap";
import { Link, Navigate } from "react-router-dom";
import "./index.css";

const CardObra = (props) => {
  const { obra } = props;
  
  return (
    <>
      <Link to={`/obra/${obra.id}`}>
        <div className="card-obra bg-white shadow">
          <img src={obra.logo} alt="Logo obra"/>
          <span className="title">
            {obra.name}
          </span>
          <span className="type">CONSTRUÇÃO</span>
          {/* <div>
            <span>90%</span>
          </div> */}
        </div>
      </Link>
    </>
    // <Card style={{ width: "22rem" }}>
    //
    //     <Card.Img variant="top" src={obra.logo} />
    //     <Card.Body>
    //       <Card.Title>{obra.name}</Card.Title>
    //       <Card.Text>
    //         Some quick example text to build on the card title and make up the
    //         bulk of the card's content.
    //       </Card.Text>
    //     </Card.Body>
    //   </Link>
    // </Card>
  );
};

export default CardObra;
