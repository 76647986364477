import React, { useEffect } from "react";
import "./index.css";
import { Container } from "react-bootstrap";
import {
  CircularInput,
  CircularTrack,
  CircularProgress,
  CircularThumb,
} from "react-circular-input";

const ChartNumber = ({ obra }) => {
  useEffect(() => {
    // console.log(obra);
  }, [obra]);
  return (
    <Container className="indicativos">
      <div className="info">
        <div className="grupo-dados">
          <span>Construtora</span>
          <span className="text-l font-bold tracking-tight text-blue-900">
            {obra?.construtora}
          </span>
        </div>
        <div className="grupo-dados">
          <span>Responsável</span>
          <span className="text-l font-bold tracking-tight text-blue-900">
            {obra?.reponsavel}
          </span>
        </div>
      </div>
      <div className="body-percentual">
        <CircularInput
          className="percentual shadow"
          value={obra?.acumuladoMeta / 100}
        >
          <CircularTrack stroke="#c5e2cf" />
          <CircularProgress stroke={`green`} />
          <text
            x={100}
            y={100}
            textAnchor="middle"
            dy="0.3em"
            fontWeight="bold"
          >
            {obra?.acumuladoMeta.toFixed(2)}%
          </text>
        </CircularInput>
        <CircularInput
          className="percentual shadow"
          value={obra?.acumuladoGerencial / 100}
        >
          <CircularTrack />
          <CircularProgress />
          <text
            x={100}
            y={100}
            textAnchor="middle"
            dy="0.3em"
            fontWeight="bold"
          >
            {obra?.acumuladoGerencial.toFixed(2)}%
          </text>
        </CircularInput>
        <CircularInput
          className="percentual shadow"
          value={obra?.acumuladoRealizado / 100}
        >
          <CircularTrack stroke="#fadfda" />
          <CircularProgress stroke={`red`} />
          <text
            x={100}
            y={100}
            textAnchor="middle"
            dy="0.3em"
            fontWeight="bold"
          >
            {obra?.acumuladoRealizado.toFixed(2)}%
          </text>
        </CircularInput>
      </div>
    </Container>
  );
};

export default ChartNumber;
