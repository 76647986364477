import React, { useEffect, useState } from "react";
import { app, db } from "../../firebase/firebaseConfig";
import { collection, getDocs, onSnapshot } from "firebase/firestore";
import { Container, Image } from "react-bootstrap";
import CardObra from "../../components/card";
import HeaderHome from "../../components/header";
import "./index.css";

const Obras = (props) => {
  const [obras, setObras] = useState([]);

  useEffect(() => {
    const colRef = collection(db, "obras");

    const unsubscribe = onSnapshot(colRef, (snapshot) => {
      let docs = [];
      snapshot.forEach((doc) => {
        docs.push({
          name: doc.data().name,
          logo: doc.data().logo,
          id: doc.id,
        });
      });
      setObras(docs);
    });

    return () => unsubscribe();
  }, []);

  return (
    <>
      <div className="min-h-full">
        <HeaderHome/>
        <header className="bg-white shadow">
          <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
            <h3 className="text-3xl font-bold tracking-tight text-blue-900">
              Obras
            </h3>
          </div>
        </header>
        <main className="list-obras mx-auto max-w-7xl px-0 py-6 sm:px-6 lg:px-8">
          {obras.length > 0 &&
            obras.map((obra) => <CardObra key={obra.id} obra={obra} />)}
        </main>
      </div>
    </>
  );
};

export default Obras;
