import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import HeaderHome from "../../components/header";
import TabelaObra from "../../components/tabelaObra";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { db } from "../../firebase/firebaseConfig";
import "./index.css";
import ChartNumber from "../../components/chart-obra";
import { differenceInMonths, format, startOfMonth, endOfMonth } from "date-fns";
import { ptBR } from "date-fns/locale/pt-BR";

const date = new Date();
const year = date.getFullYear();
const month = `0${date.getMonth() + 1}`.slice(-2);
const day = `0${date.getDate()}`.slice(-2);

const formattedDate = `${year}-${month}-${day}`;

const Obra = (props) => {
  const { obraId } = useParams();
  const [obra, setObra] = useState(null);
  const [filterEmpreiteiro, setFilterEmpreiteiro] = useState([
    { value: 0, label: "Sem Filtro" },
  ]);
  const [filterNowEmpreiteiro, setFilterNowEmpreiteiro] = useState("SEM");

  const [info, setInfo] = useState({
    status: formattedDate,
    showSumary: true,
    startFilter: "2024-03-01",
    endFilter: "2024-03-31",
  });

  const isFimDeSemana = (date) => {
    const day = date.getDay();
    return day === 0 || day === 6; // 0 representa domingo e 6 representa sábado
  };
  const isFeriado = (date, holidayList) => {
    const dateString = date.toISOString().slice(0, 10); // Obtém a string da data no formato "YYYY-MM-DD"
    return holidayList.includes(dateString);
  };

  const filterDiasUteis = (inicio, termino, feriados) => {
    let count = 0;
    let currentDate = new Date(inicio);
    const endDateObj = new Date(termino);

    while (currentDate <= endDateObj) {
      if (isFimDeSemana(currentDate) || isFeriado(currentDate, feriados)) {
        count++; // Incrementa o contador se for fim de semana ou feriado
      }
      currentDate.setDate(currentDate.getDate() + 1); // Avança para o próximo dia
    }

    return count;
  };

  const getMeses = (inicio, termino, feriados) => {
    const meses = [];

    termino = endOfMonth(termino);
    const diferencaMeses = differenceInMonths(termino, inicio);
    inicio = startOfMonth(inicio);

    for (let i = 0; i <= diferencaMeses; i++) {
      const formatter = format(inicio, "MMM/yyyy", { locale: ptBR });

      const inicioMes = startOfMonth(inicio);
      let fimMes = endOfMonth(inicio);
      let isUtil = filterDiasUteis(fimMes, fimMes, feriados);

      if (isUtil > 0) {
        while (isUtil > 0) {
          fimMes.setDate(fimMes.getDate() - isUtil);
          isUtil = filterDiasUteis(fimMes, fimMes, feriados);
        }
      }

      let mes = {
        label: formatter,
        start: format(inicioMes, "yyyy-MM-dd", { locale: ptBR }),
        end: format(fimMes, "yyyy-MM-dd", { locale: ptBR }),
      };

      meses.push(mes);
      inicio.setMonth(inicio.getMonth() + 1);
    }
    return meses;
  };

  useEffect(() => {
    const collectionRef = collection(db, "obras");
    const ref = doc(collectionRef, obraId);
    const unsubscribe = onSnapshot(ref, (snapshot) => {
      let meses = getMeses(
        new Date(snapshot.data().tarefas[0].inicio_gerencial),
        new Date(snapshot.data().tarefas[0].termino_gerencial),
        snapshot.data().feriados
      );

      const listaObra = {
        ...snapshot.data(),
        meses: getPrevstosAcumulados(
          snapshot.data().tarefas,
          snapshot.data().feriados,
          meses
        ),
        acumuladoGerencial: 0,
        acumuladoRealizado: 0,
        acumuladoMeta: 0,
      };

      setObra(listaObra);
    });

    return () => unsubscribe();
  }, []);

  const getPrevstosAcumulados = (listaTarefas, listaFeriados, meses) => {
    let tarefas = [...listaTarefas];
    let feriados = [...listaFeriados];

    meses.forEach((mes) => {
      let acumuladoGerencial = 0;
      let acumuladoMeta = 0;

      let dtStatus = new Date(mes.end);
      dtStatus.setHours(0, 0, 0, 0);
      dtStatus.setDate(dtStatus.getDate() + 1);

      tarefas.forEach((tarefa, index) => {
        let dtInicioGerencial = new Date(tarefa.inicio_gerencial);
        let dtTerminoGerencial = new Date(tarefa.termino_gerencial);

        const duracaoGerencialMS = dtTerminoGerencial - dtInicioGerencial;

        const duracaoGerencial =
          Math.round(duracaoGerencialMS / (1000 * 60 * 60 * 24)) + 1;

        let notIsUteisGerencial = filterDiasUteis(
          tarefa.inicio_gerencial,
          tarefa.termino_gerencial,
          feriados
        );

        const duracaoGerencialReal = duracaoGerencial - notIsUteisGerencial;

        tarefa.duracaoReal = duracaoGerencialReal;

        if (dtStatus >= dtInicioGerencial && dtStatus <= dtTerminoGerencial) {
          if (!tarefa.is_sumary) {
            let notIsUteisStatus = filterDiasUteis(
              tarefa.inicio_gerencial,
              mes.end,
              feriados
            );

            const duracaoStatusMS = dtStatus - dtInicioGerencial;

            const duracaoStatus =
              Math.round(duracaoStatusMS / (1000 * 60 * 60 * 24)) + 1;

            const duracaoStatusReal = duracaoStatus - notIsUteisStatus;

            tarefa.duracaoStatusReal = duracaoStatusReal;

            tarefa.previsto = Math.floor(
              (duracaoStatusReal / duracaoGerencialReal) * 100
            );
          }
        } else if (
          dtStatus < dtInicioGerencial &&
          dtStatus < dtTerminoGerencial
        ) {
          tarefa.previsto = 0;
        } else if (
          dtStatus > dtInicioGerencial &&
          dtStatus > dtTerminoGerencial
        ) {
          tarefa.previsto = 100;
        }

        tarefa.percentualGerencial = !tarefa.is_sumary
          ? tarefa.previsto * (tarefa.peso / 10000)
          : 0;
      });

      mes.acumuladoGerencial = parseFloat(
        tarefas
          .reduce((acc, item) => acc + item.percentualGerencial, 0)
          .toFixed(2)
      );
    });
    console.table(meses);
    return meses;
  };

  useEffect(() => {
    if (obra) {
      let lista = new Set();
      obra.tarefas.filter((tarefa) => {
        const valor = tarefa.empreiteiro.toUpperCase();
        if (!lista.has(valor) && valor !== "") {
          lista.add(valor);
        }
      });
    }
  }, [filterEmpreiteiro]);

  useEffect(() => {
    let dtStatus = new Date(info.status);
    dtStatus.setHours(0, 0, 0, 0);
    dtStatus.setDate(dtStatus.getDate() + 1);
    // console.log(dtStatus);
    // console.log(info);

    if (obra) {
      let lista = new Set();
      let empreiteiteiros = [];

      obra.tarefas.filter((tarefa) => {
        const valor = tarefa.empreiteiro.toUpperCase();
        if (!lista.has(valor) && valor !== "") {
          lista.add(valor);
          empreiteiteiros.push({value: valor, label: valor}); 
        }
      });
     
      setFilterEmpreiteiro(
        [{ value: "SEM", label: "Sem Filtro" }].concat(empreiteiteiros)
      );
      obra.tarefas.forEach((tarefa, index) => {
        //Calcular previsto meta
        const dtInicioMeta = new Date(tarefa.inicio_inicial);
        const dtTerminoMeta = new Date(tarefa.termino_inicial);

        const duracaoMetaMS = dtTerminoMeta - dtInicioMeta;
        const duracaoMeta =
          Math.round(duracaoMetaMS / (1000 * 60 * 60 * 24)) + 1;

        let notIsUteisMeta = filterDiasUteis(
          tarefa.inicio_inicial,
          tarefa.termino_inicial,
          obra.feriados
        );

        const duracaoMetaReal = duracaoMeta - notIsUteisMeta;
        tarefa.duracaoMetaReal = duracaoMetaReal;
        //Fim

        //Calcular previsto gerencial
        const dtInicioGerencial = new Date(tarefa.inicio_gerencial);
        const dtTerminoGerencial = new Date(tarefa.termino_gerencial);

        const duracaoGerencialMS = dtTerminoGerencial - dtInicioGerencial;
        const duracaoGerencial =
          Math.round(duracaoGerencialMS / (1000 * 60 * 60 * 24)) + 1;

        let notIsUteisGerencial = filterDiasUteis(
          tarefa.inicio_gerencial,
          tarefa.termino_gerencial,
          obra.feriados
        );

        const duracaoGerencialReal = duracaoGerencial - notIsUteisGerencial;
        tarefa.duracaoReal = duracaoGerencialReal;
        //Fim
        if (
          dtStatus >= dtInicioMeta &&
          dtStatus <= dtTerminoMeta &&
          !tarefa.is_sumary
        ) {
          //Lógica previsto meta
          let notIsUteisStatusMeta = filterDiasUteis(
            tarefa.inicio_inicial,
            info.status,
            obra.feriados
          );

          const duracaoStatusMSMeta = dtStatus - dtInicioMeta;

          const duracaoStatusMeta =
            Math.round(duracaoStatusMSMeta / (1000 * 60 * 60 * 24)) + 1;

          const duracaoStatusMetaReal =
            duracaoStatusMeta - notIsUteisStatusMeta;

          tarefa.duracaoStatusMetaReal = duracaoStatusMetaReal;

          tarefa.previstoMeta = Math.floor(
            (duracaoStatusMetaReal / duracaoMetaReal) * 100
          );
        } else if (dtStatus < dtInicioMeta && dtStatus < dtTerminoMeta) {
          tarefa.previstoMeta = 0;
        } else if (dtStatus > dtInicioMeta && dtStatus > dtTerminoMeta) {
          tarefa.previstoMeta = 100;
        }

        tarefa.percentualMeta = !tarefa.is_sumary
          ? tarefa.previstoMeta * (tarefa.peso_inicial / 10000)
          : 0;

        if (
          dtStatus >= dtInicioGerencial &&
          dtStatus <= dtTerminoGerencial &&
          !tarefa.is_sumary
        ) {
          //Lógica previsto gerencial
          let notIsUteisStatus = filterDiasUteis(
            tarefa.inicio_gerencial,
            info.status,
            obra.feriados
          );

          const duracaoStatusMS = dtStatus - dtInicioGerencial;

          const duracaoStatus =
            Math.round(duracaoStatusMS / (1000 * 60 * 60 * 24)) + 1;

          const duracaoStatusReal = duracaoStatus - notIsUteisStatus;

          tarefa.duracaoStatusReal = duracaoStatusReal;

          tarefa.previsto = Math.floor(
            (duracaoStatusReal / duracaoGerencialReal) * 100
          );
        } else if (
          dtStatus < dtInicioGerencial &&
          dtStatus < dtTerminoGerencial
        ) {
          tarefa.previsto = 0;
        } else if (
          dtStatus > dtInicioGerencial &&
          dtStatus > dtTerminoGerencial
        ) {
          tarefa.previsto = 100;
        }

        tarefa.percentualGerencial = !tarefa.is_sumary
          ? tarefa.previsto * (tarefa.peso / 10000)
          : 0;

        //Fim gerencial
        tarefa.percentualRealizado = !tarefa.is_sumary
          ? tarefa.realizado * (tarefa.peso / 10000)
          : 0;
      });
      obra.acumuladoMeta = obra.tarefas.reduce(
        (acc, item) => acc + item.percentualMeta,
        0
      );
      obra.acumuladoGerencial = obra.tarefas.reduce(
        (acc, item) => acc + item.percentualGerencial,
        0
      );
      obra.acumuladoRealizado = obra.tarefas.reduce(
        (acc, item) => acc + item.percentualRealizado,
        0
      );
    }
  }, [obra, info]);

  useEffect(() => {
    // console.log(info);
  }, [info]);

  return (
    <>
      <div className="min-h-full">
        <HeaderHome />
        <header className="bg-white shadow">
          <div className="hearder-obra mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
            <span className="text-l font-bold tracking-tight text-blue-900">
              {obra?.name}
            </span>
            <div className="controller-obra">
              {/* <Button variant="outline-primary">Dashboard</Button>
              <Button variant="outline-primary">Tarefas</Button> */}
              <div className="input-group-obra">
                <span>Filtro Empreiteiros</span>
                <Form.Select
                  onChange={(e) => {
                     setFilterNowEmpreiteiro(e.target.value);
                  }}
                  aria-label="Default select example"
                >
                  {filterEmpreiteiro.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div className="input-group-obra">
                <span>Início filtro</span>
                <Form.Control
                  type="date"
                  value={info.startFilter}
                  onChange={(e) => {
                    setInfo((prev) => ({
                      ...prev,
                      startFilter: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="input-group-obra">
                <span>Término filtro</span>
                <Form.Control
                  type="date"
                  value={info.endFilter}
                  onChange={(e) => {
                    setInfo((prev) => ({
                      ...prev,
                      endFilter: e.target.value,
                    }));
                  }}
                />
              </div>

              <div className="input-group-obra">
                <span>Status</span>
                <Form.Control
                  type="date"
                  value={info.status}
                  onChange={(e) => {
                    setInfo((prev) => ({
                      ...prev,
                      status: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
          </div>
        </header>
        <main className="list-obras mx-auto max-w-7xl px-0 py-6 sm:px-6 lg:px-8">
          <ChartNumber obra={obra} />
          <TabelaObra tarefas={obra?.tarefas} info={info} setInfo={setInfo} filterNowEmpreiteiro={filterNowEmpreiteiro} />
        </main>
      </div>
    </>
  );
};

export default Obra;
