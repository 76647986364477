import React, { useEffect, useState } from "react";
import "./index.css";
import Table from "react-bootstrap/Table";
import { Container } from "react-bootstrap";
import RowTable from "./Rows";

const TableTarefas = (props) => {
  const { data, filterTable, page } = props;
  const [tarefaFilters, setTarefaFilters] = useState([]);

  let tarefas = data.tarefas;

  useEffect(()=>{
    let tarefas = data.tarefas;

    setTarefaFilters(tarefas);

  },[data])

  useEffect(() => {
    if (filterTable.empreiteiro != null && filterTable.justificativa != null) {
      let filter = tarefas.filter((tarefa) => {
        return (
          tarefa.empreiteiro.toUpperCase() ===
            filterTable.empreiteiro.toUpperCase() &&
          tarefa.justificativa.toUpperCase() ===
            filterTable.justificativa.toUpperCase()
        );
      });
      if(filterTable.atraso){
        filter = filter.filter((tarefa) => {
          return tarefa.previsto > tarefa.realizado;
        });
      }

      setTarefaFilters(filter);
    } else if (filterTable.empreiteiro != null) {

      let filter = tarefas.filter((tarefa) => {
        return (
          tarefa.empreiteiro.toUpperCase() ===
          filterTable.empreiteiro.toUpperCase()
        );
      });

      if(filterTable.atraso){
        filter = filter.filter((tarefa) => {
          return tarefa.previsto > tarefa.realizado;
        });
      }

      setTarefaFilters(filter);
    } else if (filterTable.justificativa != null) {
      let filter = tarefas.filter((tarefa) => {
        return (
          tarefa.justificativa.toUpperCase() ===
          filterTable.justificativa.toUpperCase()
        );
      });

      if(filterTable.atraso){
        filter = filter.filter((tarefa) => {
          return tarefa.previsto > tarefa.realizado;
        });
      }

      setTarefaFilters(filter);
    } else if (!filterTable.empreiteiro && !filterTable.justificativa) {
      if(filterTable.atraso){
        tarefas = tarefas.filter((tarefa) => {
          return tarefa.previsto > tarefa.realizado;
        });
      }
      setTarefaFilters(tarefas);
    }

  }, [filterTable]);

  return (
    <Container fluid className="table-obra-now">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>QT</th>
            <th>OBRA</th>
            <th>LOCAL</th>
            <th>SERVIÇO</th>
            <th>EMPREITEIRO</th>
            <th>JUSTIFICATIVA</th>
            <th>PREVISTO</th>
            <th>REALIZADO</th>
            <th>SITUAÇÃO</th>
          </tr>
        </thead>
        <tbody>
          {tarefaFilters.length > 0 ? (
            tarefaFilters.map((tarefa, index) => (
              <RowTable key={index} tarefa={tarefa} index={index} />
            ))
          ) : (
            <tr>
              <td className="text-center" colSpan="9">
                Nenhuma atividade foi encontrada
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
};

export default TableTarefas;
