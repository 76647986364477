import { useEffect, useState } from "react";
import "./index.css";
import { Container, Tab, Tabs } from "react-bootstrap";
import DashboardAba from "./Dashboard";
import ClipLoader from "react-spinners/ClipLoader";

function TabObra(props) {

  const { obras, dtSemana } = props;
  const [page, setPage] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      setPage(obras.names()[0]);
    }, 1000);
  }, []);

  useEffect(() => {
    if(dtSemana){

      // console.log(dtSemana);

    }
  }, [dtSemana]);

  useEffect(() => {
    if (page) {
      filterTarefas();
    }
  }, [page]);

  const filterTarefas = () => {

    if (page !== "TODOS") {

      let obra = obras.lista.filter((item) => {
        return item.name === page;
      });

      if (obra.length > 0) {
        
        obra = obra[0];
        
        setData({
          tarefas: obra.tarefasWithName(),
          total: obra.countTarefas(),
          atraso: obra.countEmAtraso(),
          empreiteiroEmAtraso: obra.empreiteirosAtraso(),
          justificativaAtraso: obra.justificativaAtraso()
        });

      } else {

        setData({
          tarefas: [],
          total: 0,
          atraso: 0,
          empreiteiroEmAtraso: [],
          justificativaAtraso: []
        });

      }
    } else {

      const tarefas = obras.allTarefas();

      const atraso = tarefas.filter((item) => {
        return item.previsto > item.realizado;
      });

      setData({
        tarefas: tarefas,
        total: tarefas.length,
        atraso: atraso.length,
        empreiteiroEmAtraso: obras.allAtrasoEmpreiteiro(),
        justificativaAtraso: obras.allAtrasoJustificativa()
      });
    }
  };

  return (
    <>
      {page && data ? (
        <Tabs
          defaultActiveKey={page}
          onSelect={(k) => setPage(k)}
          className="mb-3 tab-obra"
        >
          {obras.names().map((name, index) => (
            <Tab key={index} eventKey={name} title={name}>
              {name === page && (
                <DashboardAba key={index} data={data} page={page}/>
              )}
            </Tab>
          ))}
        </Tabs>
      ) : (
        <Container className="body-loading">
          <ClipLoader color="#415280" loading="true" size={100} />
        </Container>
      )}
    </>
  );
}

export default TabObra;
