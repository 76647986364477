import { useEffect, useState } from "react";
import "./index.css";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { Container } from "react-bootstrap";

let root = null;

function ChartJustificativa(props) {
  const {
    data,
    filterTable,
    dadosFilter,
    setDadosFilter,
    page,
    setFilterTable,
  } = props;

  const resetFilterJusttificativa = (tarefas) => {
    let lista = new Set();
    tarefas.filter((tarefa) => {
      const valor = tarefa.justificativa.toUpperCase();
      if (!lista.has(valor) && valor !== "") {
        lista.add(valor);
      }
    });
    let justificativas = [];
    Array.from(lista).forEach((valor) => {
      const total = tarefas.filter((tarefa) => {
        return tarefa.justificativa.toUpperCase() === valor;
      });
      const atraso = total.filter((tarefa) => {
        return tarefa.previsto > tarefa.realizado;
      });
      justificativas.push({
        name: valor,
        total: total.length,
        atraso: atraso.length,
      });
    });
    return justificativas
      .filter((justificativa) => justificativa.atraso > 0)
      .sort((a, b) => b.atraso - a.atraso);
  };

  const resetFilterEmpreiteiro = (tarefas) => {
    let lista = new Set();
    tarefas.filter((tarefa) => {
      const valor = tarefa.empreiteiro.toUpperCase();
      if (!lista.has(valor) && valor !== "") {
        lista.add(valor);
      }
    });
    let empreiteiros = [];
    Array.from(lista).forEach((valor) => {
      const total = tarefas.filter((tarefa) => {
        return tarefa.empreiteiro.toUpperCase() === valor;
      });
      const atraso = total.filter((tarefa) => {
        return tarefa.previsto > tarefa.realizado;
      });
      empreiteiros.push({
        name: valor,
        total: total.length,
        atraso: atraso.length,
      });
    });
    return empreiteiros
      .filter((empreiteiro) => empreiteiro.atraso > 0)
      .sort((a, b) => b.atraso - a.atraso);
  };

  useEffect(() => {
    
    let currentData = data;

    if (filterTable.justificativa) {

      if (page !== "TODOS") {
        let tarefasFilter = currentData.tarefas.filter(
          (tarefa) =>
            tarefa.justificativa === filterTable.justificativa &&
            tarefa.obra === page
        );
        let justificativaAtraso = resetFilterJusttificativa(tarefasFilter);
        let empreiteiroEmAtraso = resetFilterEmpreiteiro(tarefasFilter);

        setDadosFilter({
          empreiteiroEmAtraso,
          justificativaAtraso,
        });
      } else {
        let tarefasFilter = currentData.tarefas.filter(
          (tarefa) => tarefa.justificativa === filterTable.justificativa
        );
        let justificativaAtraso = resetFilterJusttificativa(tarefasFilter);
        let empreiteiroEmAtraso = resetFilterEmpreiteiro(tarefasFilter);

        setDadosFilter({
          empreiteiroEmAtraso,
          justificativaAtraso,
        });
      }
    } else if(!filterTable.justificativa && !filterTable.empreiteiro) {
     
      setDadosFilter({
        empreiteiroEmAtraso: data.empreiteiroEmAtraso,
        justificativaAtraso: data.justificativaAtraso,
      });
    }
  }, [filterTable]);

  useEffect(() => {
    if (document.getElementById("chart-justificativa")) {
      root = am5.Root.new("chart-justificativa");
      root.setThemes([am5themes_Animated.new(root)]);

      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: "panX",
          paddingLeft: 0,
          layout: root.verticalLayout,
        })
      );
      // Data
      let dados = dadosFilter.justificativaAtraso;

      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      let yAxis = chart.yAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: "name",
          renderer: am5xy.AxisRendererY.new(root, {
            inversed: true,
            cellStartLocation: 0.2,
            cellEndLocation: 0.9,
            minorGridEnabled: true,
            minGridDistance: 10,
          }),
        })
      );

      yAxis.data.setAll(dados);

      let xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererX.new(root, {
            strokeOpacity: 0.1,
            minGridDistance: 50,
          }),
          min: 0,
          max: 12,
        })
      );

      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      function createSeries(field, name) {
        let series = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            name: name,
            xAxis: xAxis,
            yAxis: yAxis,
            fill:
              field === "atraso" ? am5.color(0x6794dc) : am5.color(0xb34800),
            valueXField: field,
            stacked: true,
            categoryYField: "name",
            sequencedInterpolation: true,
            tooltip: am5.Tooltip.new(root, {
              pointerOrientation: "horizontal",
              labelText: "{name}: {valueX}",
              color: "white",
            }),
          })
        );

        series.columns.template.events.on("click", (event) => {
          const value = event.target.dataItem.dataContext.name;
          setFilterTable((prev) => ({
            ...prev,
            justificativa: value,
          }));
        });

        series.columns.template.states.create("hover", {
          fill: new am5.color("#14416e"),
        });

        series.columns.template.setAll({
          height: am5.p100,
          strokeOpacity: 0,
        });

        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            locationX: 1,
            locationY: 0.5,
            sprite: am5.Label.new(root, {
              centerY: am5.p50,
              text: "{valueX}",
              populateText: true,
            }),
          });
        });

        series.data.setAll(dados);
        series.appear();

        return series;
      }

      createSeries("atraso", "ATRASO");

      // Add cursor
      // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
      let cursor = chart.set(
        "cursor",
        am5xy.XYCursor.new(root, {
          behavior: "zoomY",
        })
      );
      cursor.lineY.set("forceHidden", true);
      cursor.lineX.set("forceHidden", true);

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.appear(1000, 100);
      return () => {
        root.dispose();
      };
    }
  }, [dadosFilter]);

  return (
    <Container className="body-chart">
      <span>CAUSA</span>
      <div id="chart-justificativa"></div>
    </Container>
  );
}

export default ChartJustificativa;
