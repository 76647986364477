import { useState, createContext } from "react";
import {app} from "../firebase/firebaseConfig"
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);

  const auth = getAuth(app);

  const SignIn = async (email, password) => {
    try {
        const userCredencial = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );
        setUser(userCredencial.user);
        localStorage.setItem('@auth:macros', JSON.stringify(userCredencial.user));
        return true;
      } catch (error) {
        setError("Error during login:", error.message);
        return false;
      }
  }

  const isAuthenticated = () => {

    if(localStorage.getItem('@auth:macros')){
        setUser(JSON.parse(localStorage.getItem('@auth:macros')));
        return true
    }

    return false;

  }

  return (
    <AuthContext.Provider
      value={{
        user,
        SignIn,
        isAuthenticated,
        logout: () => {
          setUser(null);
          localStorage.removeItem('@auth:macros');
        },
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
