import React, { useContext } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { AuthContext } from "../context/auth";
import Dashboard from "../pages/dashboard";
import Obras from "../pages/obras";
import Obra from "../pages/obra";
import Login from "../pages/login";

const AppRouter = () => {
  const { isAuthenticated } = useContext(AuthContext);
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Login />} />
        <Route exact path="/obra" element={isAuthenticated() ? <Obras/> : <Navigate to="/"/> } />
        <Route path="/obra/:obraId" element={isAuthenticated() ? <Obra/> : <Navigate to="/"/> } />
        <Route path="/dashboard" element={<Dashboard/> } />
        <Route path="*" element={<Navigate to="/"/>} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
